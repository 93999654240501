import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { examplesData } from "../../components/examples/examples-data";

import Layout from "../../components/theme/layout";
import Seo from "../../components/seo";

const AJOffice = () => {
    const example = examplesData[0]
    return (
        <Layout>
            <Seo title={example.key}/>

            <ExamplePageWrapper>
                <ContentWrapper>
                    <QiwioVideo src={example.contentUrl} frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                    <QiwioVideoMobile src={example.contentUrlMobile} frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideoMobile>
                </ContentWrapper>
                <InfoWrapper>
                    <h1>{example.title}</h1>
                    <h2>{example.customer}</h2>
                    <p>{example.description}</p>
                    <StatsWrapper>
                        {example.stat.map(({number, statDescription}) => (
                            <Stat>
                                <h3>{number}</h3>
                                <p>{statDescription}</p>
                            </Stat>
                        ))}
                    </StatsWrapper>
                    <CTAWrapper>
                        <Link to='/pricing'>View pricing</Link>
                        <Link to='/contact-sales'>Talk to us</Link>
                    </CTAWrapper>
                </InfoWrapper>
            </ExamplePageWrapper>
        </Layout>
    )
}
const ExamplePageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
    min-height: 70vh;

    @media screen and (min-width: 900px) {
        padding: 100px 50px;
        flex-direction: row;
    }
`
const ContentWrapper = styled.div`
    width: 100%;
    margin-right: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (min-width: 900px) {
        width: 60%;
        margin-bottom: 0;
    }
`
const InfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-size: 2rem;
        margin-bottom: 5px;
    }
    h2 {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 1.5rem;
    }
    p {
        font-size: 1rem;
        margin-bottom: 40px;
    }

    @media screen and (min-width: 900px) {
        width: 40%;
    }
`
const StatsWrapper = styled.div`
    display: flex;
    margin-bottom: 50px;
`
const Stat = styled.div`
    text-align: center;

    &:not(:last-child) {
        margin-right: 50px;

        @media screen and (max-width: 600px) {
            margin-right: 25px;
        }
    }
    h3 {
        color: #ff5679;
        font-size: 2rem;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
        line-height: 1.2;
    }
`
const QiwioVideo = styled.iframe`
    border: none;
    width: 100%;
    //height: 100%;
    border-radius: 10px;
    aspect-ratio: 16 / 6;
    display: block;

    @media screen and (max-width: 900px) {
        min-height: 190px;
        aspect-ratio: 16 / 6;
        max-width: 90vw;
        border-radius: 5px;
        display: none;
    }
`
const QiwioVideoMobile = styled.iframe`
    border: none;
    width: 100%;
    //height: 100%;
    border-radius: 10px;
    aspect-ratio: 4 / 3;
    display: none;

    @media screen and (max-width: 900px) {
        min-height: 190px;
        aspect-ratio: 4 / 3;
        max-width: 90vw;
        border-radius: 5px;
        display: block;
    }
`
const CTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 100%;

    a {
        color: white;
        text-align: center;
        border-radius: 3px;
        padding: 10px 20px;
        font-weight: 600;
        transition: all .4s;

        &:hover {
            transform: translateY(-5px);
        }

        &:first-child {
            border: 1px solid white;
        }

        &:last-child {
            border: 1px solid #ff5679;
            background-color: #ff5679;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: 900px) {
        max-width: 300px;
    }
`
export default AJOffice